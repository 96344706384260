import React from "react";
import Loading from "../GlobalComponents/Loading/Loading";
import { Form, Row, Container, Col, Button, Alert } from "react-bootstrap";
import ModalAlert from "../GroupLists/ModalAlert/ModalAlert";
import axios from "axios";

class CreateNewUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      userName: "",
      userEmail: "",
      errors: []
    };
  }

  componentDidMount() {
    axios("https://listsever.e-decanat-ifnmu.site/api.php", {
      method: "post",
      data: {
        task: "getCurentStudetData"
      },
      withCredentials: true
    }).then(response =>
      this.setState({
        userName:
          response.data.data.slastname + " " + response.data.data.sfirstname,
        userEmail: response.data.data.semail,
        scourse: response.data.data.scourse,
        sgroup: response.data.data.sgroup,
        loading: false
      })
    );
  }

  regsitrarion = () => {
    window.location.href =
      "http://listsever.e-decanat-ifnmu.site/deleteUser.php";
    return null;
  };

  render() {
    console.log(this.state);
    if (this.state.loading) {
      return (
        <div>
          <Loading />
        </div>
      );
    }

    return (
      <div>
        <Container>
          <Row>
            <Col>
              <div>
                <strong>Ваше ім'я/Your Name: </strong> {this.state.userName}
              </div>
              <div>
                <strong>Ваш email/Your email: </strong> {this.state.userEmail}
              </div>
              <div>
                <strong>Ваш курс/Your Course: </strong> {this.state.scourse}
              </div>
              <div>
                <strong>Ваша група/Your group: </strong> {this.state.sgroup}
              </div>

              <Alert variant="warning">
                Якщо потрібо змінити курс або групу, Ви можете зареєструватись
                на сайті знову натиснувши кнопку нижче
              </Alert>
              <Alert variant="warning">
                If you need to change course or group, you can register again by
                clicking the button below
              </Alert>
              <Button variant="danger" onClick={() => this.regsitrarion()}>
                Зареєструватись заново/Register again
              </Button>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default CreateNewUser;
