import React from "react";
import Form from "react-bootstrap/Form";
import ListsDate from "./OneListComponents/ListsDate";
import ListsTable from "./OneListComponents/ListsTable/ListsTable";
import ListsRestriction from "./OneListComponents/ListsRestriction";

const OneList = props => {
  let table = props.listdata;
  return (
    <div>
      <div className={"ListDepartmentName"}>{table.department}</div>
      <div className={"ListName"}>{table.name}</div>
      <ListsDate timestamp={table.date} />
      <Form>
        <div key={`default-checkbox`} className="mb-3">
          <Form.Check
            type="checkbox"
            id={`default-checkbox`}
            label={`Показувати тільки вільні місця/Show only available places`}
            onChange={() => props.showFreeRecords(table.ListIndex)}
          />
        </div>
      </Form>
      <ListsRestriction restriction={table.restriction} />
      <ListsTable
        table={table}
        buttonPress={props.buttonPress}
        showFreeRecords={table.showFreeRecords}
        global_language={props.global_language}
        changeStatusRequest={props.changeStatusRequest}
      />
    </div>
  );
};

export default OneList;
