import React, { Component } from "react";
import "./App.css";
import { BrowserRouter, Route } from "react-router-dom";
import GroupLists from "./GroupLists/GroupLists";
import Departments from "./Departments";
import Department from "./Department";
import Head from "./Head";
import BootsrapEsential from "./BootsrapEssential";
import ReportError from "./ReportError";
import ThankYou from "./ThankYou";
import EditStudentData from "./StudentPanel/EditStudentData/EditStudentData";
import CreateNewUser from "./CreateNewUser";
import Profile from "./Profile";

const NewRoute = () => {
  return (
    <div>
      <p>Hello World</p>
    </div>
  );
};

class App extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    console.log(this.state);
    return (
      <div>
        <Head />
        <BootsrapEsential />
        <BrowserRouter>
          <Route path="/" component={Departments} exact />
          <Route path={"/Department/:id"} component={Department} exact />
          <Route path="/GroupList/:id" component={GroupLists} />
          <Route path="/reporterror" component={ReportError} />
          <Route path="/ThankYou" component={ThankYou} />
          <Route path="/Profile" component={Profile} />
          <Route path="/CreateNewUser" component={CreateNewUser} />
          <Route path="/EditStudentData" component={EditStudentData} />
        </BrowserRouter>
      </div>
    );
  }
}

export default App;
