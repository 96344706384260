import React from "react";
import ReactLoading from "react-loading";
import "./Loading.css";

const Loading = () => {
  return (
    <div className="generalLoading">
      <div className="subdivLoading">
        <ReactLoading type="spin" color="#343A40" height={250} width={250} />
      </div>
      <p className="textLoading">Loading...</p>
    </div>
  );
};

export default Loading;
