import React from "react";
import Loading from "../GlobalComponents/Loading/Loading";
import { Form, Row, Container, Col, Button, Alert } from "react-bootstrap";
import ModalAlert from "../GroupLists/ModalAlert/ModalAlert";
import axios from "axios";

class CreateNewUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      userName: "",
      userEmail: "",
      userGroup: 1,
      userCourse: 1,
      registered: false,
      errors: []
    };
  }

  componentDidMount() {
    axios("https://listsever.e-decanat-ifnmu.site/api.php", {
      method: "post",
      data: {
        task: "get_data_for_new_user",
        group_id: this.props.match.params.id
      },
      withCredentials: true
    }).then(response =>
      this.setState({
        userName: response.data.data.name,
        userEmail: response.data.data.email,
        loading: false
      })
    );
  }

  regsitrarion = () => {
    axios("https://listsever.e-decanat-ifnmu.site/api.php", {
      method: "post",
      data: {
        task: "create_new_user",
        sgroup: this.state.userGroup,
        scourse: this.state.userCourse
      },
      withCredentials: true
    }).then(response => {
      this.error(response.data.error_message);
      if (response.data.status === "success") {
        window.location.replace("/");
      }
    });
  };

  changeGroup = event => {
    this.setState({ userGroup: event.target.value });
  };
  changeCourse = event => {
    this.setState({ userCourse: event.target.value });
  };

  errorClose = error => {
    console.log(this.state.errors);
    let errors = this.state.errors;
    errors = errors.filter(one => {
      if (one !== error) {
        return one;
      }
    });
    this.setState({ errors: errors });
  };
  error = errorMessage => {
    console.log(errorMessage);
    let errors = this.state.errors;
    errors.push(errorMessage);
    this.setState([{ errors: errors }]);
  };

  render() {
    console.log(this.state);
    if (this.state.loading) {
      return (
        <div>
          <Loading />
        </div>
      );
    }
    let groupArray = [];
    for (let index = 1; index < 100; index++) {
      groupArray.push(index);
    }

    return (
      <div>
        <Container>
          <Row>
            <Col>
              <Alert variant="warning">
                Уважно вибирайте Ваш курс та групу!/Choose your course and group
                carefully!
              </Alert>
              <Form>
                <div>
                  <strong>Ваше ім'я/Your Name: </strong> {this.state.userName}
                </div>
                <div>
                  <strong>Ваш email/Your email: </strong> {this.state.userEmail}
                </div>
                <Form.Group controlId="exampleForm.ControlSelect1">
                  <Form.Label>Виберіть Ваш курс/Chose your course</Form.Label>
                  <Form.Control
                    as="select"
                    onChange={event => this.changeCourse(event)}
                    value={this.state.userCourse}
                  >
                    <option value={0}>Preparation Department</option>
                    <option>1</option>
                    <option>2</option>
                    <option>3</option>
                    <option>4</option>
                    <option>5</option>
                    <option>6</option>
                  </Form.Control>
                </Form.Group>
                <Form.Group controlId="exampleForm.ControlSelect1">
                  <Form.Label>Виберіть Вашу групу/Chose your group:</Form.Label>
                  <Form.Control
                    as="select"
                    onChange={event => this.changeGroup(event)}
                    value={this.state.userGroup}
                  >
                    {groupArray.map(index => {
                      return <option>{index}</option>;
                    })}
                  </Form.Control>
                </Form.Group>
              </Form>
              <Button onClick={() => this.regsitrarion()}>
                Зареєструватись/Register
              </Button>
            </Col>
          </Row>
          <Row>
            <Col>
              <ModalAlert
                errors={this.state.errors}
                errorClose={this.errorClose}
              />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default CreateNewUser;
