import React from "react";

const ListsDate = props => {
  var global_language = "ua";
  var date = new Date(props.timestamp * 1000);
  var hours = date.getHours();
  var minutes = "0" + date.getMinutes();
  var month = date.getMonth();
  var daten = date.getDate();
  var day = date.getDay();
  var str_month = {};
  var str_day = {};
  if (global_language === "en") {
    str_month = {
      0: "January",
      1: "February",
      2: "March",
      3: "April",
      4: "May",
      5: "June",
      6: "July",
      7: "August",
      8: "September",
      9: "October",
      10: "November",
      11: "December"
    };
    str_day = {
      0: "Sunday",
      1: "Monday",
      2: "Tuesday",
      3: "Wednesday",
      4: "Thursday",
      5: "Friday",
      6: "Saturday "
    };
  } else if (global_language === "ua") {
    str_month = {
      0: "Січня",
      1: "Лютого",
      2: "Березня",
      3: "Квітня",
      4: "Травня",
      5: "Червня",
      6: "Липня",
      7: "Серпня",
      8: "Вересня",
      9: "Жовтня",
      10: "Листопада",
      11: "Грудня"
    };
    str_day = {
      0: "Неділя",
      1: "Понеділок",
      2: "Вівторок",
      3: "Середа",
      4: "Четвер",
      5: "П'ятниця",
      6: "Субота "
    };
  }

  var formattedTime =
    str_day[day] +
    " " +
    daten +
    " " +
    str_month[month] +
    " " +
    hours +
    ":" +
    minutes.substr(-2);
  return <div className={"ListDay"}>{formattedTime}</div>;
};

export default ListsDate;
