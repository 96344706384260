import React from "react";
import Loading from "../../GlobalComponents/Loading/Loading";

class EditStudentData extends React.Component {
  constructor(props) {
    super(props);
    this.state = { loading: true };
  }

  render() {
    return (
      <div>
        <Loading />
      </div>
    );
  }
}

export default EditStudentData;
