import React from "react";
import Button from "react-bootstrap/Button";

const AdminCell = ({ index, table, buttonPress }) => {
  return (
    <td>
      <Button
        size="sm"
        variant="link"
        onClick={() =>
          buttonPress({
            task: "CancleRecordAdmin",
            modalText: {
              title: "Скасування реєстрації",
              descript: "Ви дійсно бажаєте скасувати реєстрацію?",
              ok: "Так",
              cancle: "Закрити"
            },
            taskData: {
              number_in_list: index,
              list_id: table.list_id,
              listIndex: table.ListIndex
            }
          })
        }
      >
        Видалити
      </Button>
      /
      <Button
        size="sm"
        variant="link"
        onClick={() =>
          buttonPress({
            task: "BanStudent",
            modalText: {
              title: "Блокування студента",
              descript: "Ви дійсно бажаєте заблокувати даного студента?",
              ok: "Так",
              cancle: "Закрити"
            },
            taskData: {
              number_in_list: index,
              list_id: table.list_id,
              listIndex: table.ListIndex
            }
          })
        }
      >
        Заблокувати студента
      </Button>
    </td>
  );
};

export default AdminCell;
