import React, { Component } from "react";

import axios from "axios";
import OneList from "./OneList/OneList";
import ModalConfirm from "./ModalConfirm/ModalConfirm";
import Container from "react-bootstrap/Container";
import ModalAlert from "./ModalAlert/ModalAlert";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Loading from "../GlobalComponents/Loading/Loading";

class GroupLists extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      modalShow: false,
      modalData: {
        modalText: {
          title: "s",
          descript: "s",
          ok: "",
          cancle: ""
        }
      },
      errors: [],
      login: false
    };
  }

  buttonPress = props => {
    if (props.task === "CancleRecord") {
      this.setState({ modalData: props });
      this.setState({ modalShow: true });
    }

    if (props.task === "BanStudent") {
      this.setState({ modalData: props });
      this.setState({ modalShow: true });
    }

    if (props.task === "CreateRecord") {
      if (this.state.login === true) {
        this.register(props.taskData);
      } else {
        this.setState({
          modalData: {
            task: "goOkLink",
            modalText: {
              title: "Помилка/Error",
              descript: `Ви не авторизовані!
                
                Для авторизації:
                -> натисніть на кнопку "Увійти"; 
                -> введіть логін/пароль; 
                -> натисніть "Записатись на відпрацювання/
                You are not authorized!
                
                To authorize:
                -> click on the "Login" button;
                -> enter login / password;
                -> click "Sign up for testing".`,
              ok: "Увійти/Log in",
              cancle: "Скасувати/Cancle"
            },
            taskData: {
              okLink:
                "https://ifnmu.sharepoint.com/siteDeanMedical/SitePages/%D0%94%D0%BE%D0%BC%D0%B0%D1%88%D0%BD%D1%8F%D1%8F.aspx#"
            }
          }
        });
        this.setState({ modalShow: true });
      }
    }

    if (props.task === "CancleRecordAdmin") {
      this.setState({ modalData: props });
      this.setState({ modalShow: true });
    }
  };

  CancleRecordAdmin = props => {
    axios("https://listsever.e-decanat-ifnmu.site/api.php", {
      method: "post",
      data: {
        task: "CancleRecordAdmin",
        number_in_list: props.number_in_list,
        list_id: props.list_id,
        listIndex: props.listIndex
      },
      withCredentials: true
    }).then(response => this.CancleRecordAdminResult(response.data));
  };

  CancleRecordAdminResult = props => {
    props.data = "deleted";
    this.replaceRecord(props);
  };

  confirmedModalConformation = (task, taskData) => {
    this.setState({ modalShow: false });
    if (task === "CancleRecord") {
      this.cancleRegistration(taskData);
    }

    if (task === "goOkLink") {
      window.location.href = taskData.okLink;
    }

    if (task === "BanStudent") {
      this.BanStudent(taskData);
    }
    if (task === "CancleRecordAdmin") {
      this.CancleRecordAdmin(taskData);
    }
  };

  BanStudent = props => {
    axios("https://listsever.e-decanat-ifnmu.site/api.php", {
      method: "post",
      data: {
        task: "BanStudent",
        number_in_list: props.number_in_list,
        list_id: props.list_id,
        listIndex: props.listIndex
      },
      withCredentials: true
    }).then(response => this.BanStudentResult(response.data));
  };

  BanStudentResult = props => {
    props.data = "banned";
    this.replaceRecord(props);
  };

  componentDidMount() {
    axios("https://listsever.e-decanat-ifnmu.site/api.php", {
      method: "post",
      data: {
        task: "GetTablesFromGroup",
        group_id: this.props.match.params.id
      },
      withCredentials: true
    }).then(response =>
      this.setState({
        data: response.data.data,
        login: response.data.login,
        loading: false
      })
    );
  }

  register = props => {
    console.log(props);
    axios("https://listsever.e-decanat-ifnmu.site/api.php", {
      method: "post",
      data: {
        task: "CreateRecord",
        number_in_list: props.number_in_list,
        list_id: props.list_id,
        listIndex: props.listIndex
      },
      withCredentials: true
    }).then(response => this.replaceRecord(response.data));
  };

  cancleRegistration = props => {
    axios("https://listsever.e-decanat-ifnmu.site/api.php", {
      method: "post",
      data: {
        task: "CancleRecord",
        number_in_list: props.number_in_list,
        list_id: props.list_id,
        listIndex: props.listIndex
      },
      withCredentials: true
    }).then(response => this.replaceRecord(response.data));
  };

  replaceRecord = response => {
    console.log(response);
    let error = response.response.split(" ");
    console.log(error);
    error = error.filter(one => {
      if (one !== "") {
        return one;
      }
    });
    console.log(error);
    this.setState({ errors: error });
    let data = this.state.data;
    data[response.listIndex].records[response.number_in_list - 1] =
      response.data;
    this.setState({ data: data });
    console.log(data);
    console.log(response.number_in_list);
  };

  handleClose = () => {
    console.log(this.state);
    this.setState({ modalShow: false });
  };

  errorClose = error => {
    console.log(this.state.errors);
    let errors = this.state.errors;
    errors = errors.filter(one => {
      if (one !== error) {
        return one;
      }
    });
    this.setState({ errors: errors });
  };

  showFreeRecords = listIndex => {
    let data = this.state.data;
    data[listIndex].showFreeRecords = !this.state.data[listIndex]
      .showFreeRecords;
    this.setState({ data: data });
  };

  changeStatusRequest = props => {
    axios("https://listsever.e-decanat-ifnmu.site/api.php", {
      method: "post",
      data: {
        task: "changeStatus",
        status: props.status,
        number_in_list: props.number_in_list,
        list_id: props.list_id,
        listIndex: props.listIndex
      },
      withCredentials: true
    }).then(response => this.changeStatusRespond(response.data));
  };

  changeStatusRespond = props => {
    console.log(props);
    let current_state = [...this.state.data];
    console.log(
      (current_state[props.listIndex].records[props.number_in_list - 1].status =
        props.status)
    );

    console.log(current_state);
    this.setState({ data: current_state });
  };

  render() {
    console.log(this.state);

    if (this.state.loading) {
      return <Loading />;
    }
    return (
      <div className="App">
        <Container>
          {this.state.data.map((listdata, index) => {
            listdata.ListIndex = index;
            return (
              <OneList
                key={index}
                listdata={listdata}
                showFreeRecords={this.showFreeRecords}
                buttonPress={this.buttonPress}
                global_language={"ua"}
                changeStatusRequest={this.changeStatusRequest}
              />
            );
          })}
          <ModalConfirm
            show={this.state.modalShow}
            handleClose={this.handleClose}
            modalData={this.state.modalData}
            confirmedModalConformation={this.confirmedModalConformation}
          />
          <Row>
            <Col>
              <ModalAlert
                errors={this.state.errors}
                errorClose={this.errorClose}
              />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default GroupLists;
