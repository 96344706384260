import React from "react";

const TimeOrIndex = ({ index, table }) => {
  if (table.time_between === 0) {
    return <td>{index}</td>;
  }

  var minute = index * table.time_between * 1000 * 60;
  var student_time = table.date * 1000 + minute;
  let curren_time = new Date(student_time);
  var hours = curren_time.getHours();
  var minutes = "0" + curren_time.getMinutes();
  var formattedTime = hours + ":" + minutes.substr(-2);

  if (table.time_between !== 0) {
    return <td>{formattedTime}</td>;
  }
};

export default TimeOrIndex;
