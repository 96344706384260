import React from "react";

const ListsRestriction = props => {
  let restriction = props.restriction;
  let language = "ua";

  let text = "";

  if (language === "ua") {
    text = {
      title: "Доступно для/кількість місць:",
      course: "курси",
      courses: "курcів",
      all: "Всіх",
      all_courses: "Всі ",
      all_speciality: "Всіх спеціальностей"
    };
  }

  console.log(restriction);
  return (
    <div>
      <div> {text.title} </div>
      {restriction.map(row => {
        let course = row.courses;
        let speciality = row.speciality.ua;
        let free_places = row.free_places;
        let sentence = "";

        if (row.courses === "all") {
          course = text.all_courses;
        }

        if (row.courses === "0") {
          course = "Preparation Department";
        }
        console.log("restriction");
        console.log(row.courses);
        if (row.speciality === "all" || row.speciality.ua === "0") {
          speciality = "";
        }

        sentence = course + " " + text.course + ". " + speciality;
        if ((row.courses === "all") & (row.speciality === "all")) {
          sentence = text.all;
        }
        return (
          <div>
            {sentence} - {row.free_places}
          </div>
        );
      })}
    </div>
  );
};

export default ListsRestriction;
