import React from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

class ReportError extends React.Component {
  handleSubmit(event) {
    console.log(event.target.text.value);
    event.preventDefault();
  }
  render() {
    return (
      <Container>
        <Row>
          <Col>
            <div>
              <p />
              <p>
                <h2>Допоможіть зробити сайт кращим!</h2>
              </p>
              <p>
                Тут Ви можете залишити свої зауваження стосовно роботи системи.{" "}
              </p>
              <p>
                Будь ласка, опишіть детально Вашу проблему, і залишіть контакти
                для оперативного її вирішення.{" "}
              </p>
              <p />
              <Form
                onSubmit={e => this.handleSubmit(e)}
                action="https://listsever.e-decanat-ifnmu.site/reporterror.php"
              >
                <Form.Group controlId="exampleForm.ControlTextarea1">
                  <Form.Label>
                    <b>Опис:</b>
                  </Form.Label>
                  <Form.Control name="desctipt" as="textarea" rows="4" />
                </Form.Group>
                <Form.Group controlId="exampleForm.ControlInput1">
                  <Form.Label>
                    <b>Контактні дані(Viber, Telegram, Email):</b>
                  </Form.Label>
                  <Form.Control name="contact" type="text" placeholder="" />
                </Form.Group>
                <Button variant="primary" type="submit">
                  Відправити
                </Button>
              </Form>
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default ReportError;
