import React from "react";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

class ThankYou extends React.Component {
  render() {
    return (
      <Container>
        <Row>
          <Col>
            <div>
              <p />
              <p>
                <h1>Дякуємо!</h1>
                
              </p>
              <p> Ми постараємося якомога швидше вирішити Вашу проблему!</p>
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default ThankYou;
