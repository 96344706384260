import React from "react";
import { ReactComponent as Cancel } from "./cancel.svg";
import { ReactComponent as Checked } from "./checked.svg";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import "./Atandance.css";

const Atandance = ({ status, changeStatusRequest, table, index, admin }) => {
  if (!table.admin) {
    return null;
  }
  let checkedStatus = "transparent";
  let cancelStatus = "transparent";
  if (status === 1) {
    checkedStatus = "active";
  }

  if (status === 2) {
    cancelStatus = "active";
  }

  let statusCheked = 1;
  let statusCancel = 2;
  if (status === 1) {
    statusCheked = "0";
  }

  if (status === 2) {
    statusCancel = "0";
  }

  return (
    <div className="box">
      <OverlayTrigger
        overlay={<Tooltip id="tooltip-disabled">Студент був</Tooltip>}
      >
        <span className="d-inline-block">
          <div className={checkedStatus}>
            <Checked
              onClick={() => {
                changeStatusRequest({
                  status: statusCheked,
                  listIndex: table.ListIndex,
                  list_id: table.list_id,
                  number_in_list: index
                });
              }}
            />
          </div>
        </span>
      </OverlayTrigger>
      <OverlayTrigger
        overlay={<Tooltip id="tooltip-disabled">Студент не був</Tooltip>}
      >
        <span className="d-inline-block">
          <div className={cancelStatus}>
            <Cancel
              onClick={() => {
                changeStatusRequest({
                  status: statusCancel,
                  listIndex: table.ListIndex,
                  list_id: table.list_id,
                  number_in_list: index
                });
              }}
            />
          </div>
        </span>
      </OverlayTrigger>
    </div>
  );
};

export default Atandance;
