import React from "react";

const ListTableHead = ({ global_language, time_between, admin }) => {
  let table_head = [];

  var str_table_head = {
    en: {
      first_name: "First Name",
      last_name: "Last Name",
      course: "Course/Group",
      group: "Group",
      time: "Time",
      number: "#",
      attendance: "ttendance",
      control: "Control"
    },
    ua: {
      first_name: "Ім'я",
      last_name: "Прізвище",
      course: "Курс/Група",
      group: "Група",
      time: "Час",
      number: "#",
      attendance: "Відвідуваність",
      control: "Керування"
    }
  };

  if (admin) {
    table_head.push(str_table_head[global_language].attendance);
  }

  console.log(table_head);

  if (time_between === 0) {
    table_head.push(str_table_head[global_language].number);
  } else {
    table_head.push(str_table_head[global_language].time);
  }

  table_head.push(
    str_table_head[global_language].last_name,
    str_table_head[global_language].first_name,
    str_table_head[global_language].course
  );

  if (admin) {
    table_head.push(str_table_head[global_language].control);
  }
  
  return table_head.map(cell => {
    return <td key={cell}>{cell}</td>;
  });
};

export default ListTableHead;
