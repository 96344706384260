import React from "react";

import Table from "react-bootstrap/Table";
import ListTableRow from "./ListTableComponents/ListTableRow/ListTableRow";
import ListTableHead from "./ListTableComponents/ListsTableHead";

const ListsTable = ({
  table,
  global_language,
  buttonPress, 
  changeStatusRequest
}) => {
  var start_time = table.date * 1000;
  var index = 0;

  return (
    <Table hover>
      <thead>
        
          <ListTableHead
            global_language={global_language}
            time_between={table.time_between}
            admin={table.admin}
          />
        
      </thead>
      <tbody>
        {table.records.map(row => {
          index = index + 1;
          return (
            <ListTableRow
              index={index}
              table={table}
              buttonPress={buttonPress}
              row={row}
              changeStatusRequest={changeStatusRequest}
            />
          );
        })}
      </tbody>
    </Table>
  );
};

export default ListsTable;
