import React from "react";
import Alert from "react-bootstrap/Alert";
import "./ModalAlert.css";

const ModalAlert = ({ errors, errorClose }) => {
  var errorArray = {
    time_for_registration_ended: {
      title: "Реєстрацію відхилено/Registration declined",
      text:
        "Занадто пізно. Реєстрацію в даний список вже закрито./Too late. Registration to this list is already closed",
      variant: "danger"
    },
    you_cant_register_to_that_list: {
      title: "Реєстрацію відхилено/Registration declined",
      text:
        'Реєстрація в даний список для Вас недоступна. Можливі проблеми: -реєстрація вашому курсу чи спеціальності не є доступна в цей список; -Ви невірно вказали свої дані. Перевірте їх не сторінці "Ваш аккаунт"/Registration to this list is not available to you. Possible problems: -Recording to your course or specialty is not available in this list; -You incorrectly entered your information. Check them not on your account page"',
      variant: "danger"
    },
    time_for_registration_didnt_started: {
      title: "Реєстрацію відхилено/Registration declined",
      text:
        "Реєстрація в даний список ще не розпочалась./Registration to this list has not yet started.",
      variant: "danger"
    },
    record_exist: {
      title: "Реєстрацію відхилено/Registration declined",
      text:
        "Ви вже зареєстровані в цей список!/You are already registered in this list!",
      variant: "danger"
    },
    banned: {
      title: "Реєстрацію відхилено/Registration declined",
      text:
        "Реєструватись в будь-які списки Вам ЗАБОРОНЕНО! Ви заблоковані деканатом! Для зняння блокування зверніться в деканат!/You are forbidden to register in any lists! You are blocked by the dean! Contact the dean to remove the lock!",
      variant: "danger"
    },
    no_free_places: {
      title: "Реєстрацію відхилено/Registration declined",
      text: "Вільних місьць більше немає/No more seats available",
      variant: "danger"
    },
    no_free_places_for_speciality: {
      title: "Реєстрацію відхилено/Registration declined",
      text:
        'Вільних місьць для Вашої спеціальності більше немає. Зверніть увагу на пункт списку "Доступно для/кількість місць:"/There is no more space available for your specialty. Notice the "Available for / Seats:"',
      variant: "danger"
    },
    register_success: {
      title: "Реєстрація успішна/Registration successful",
      text:
        "Зверніть увагу, студенти які реєструються і не з'являються будуть заблоковані. Ви маєте можливіть скасовувати реєтрації. Не забувайте про це./Please note that students who do not enroll and do not appear will be blocked. You must be able to unregister. Don't forget about it.",
      variant: "success"
    },
    deleted_success: {
      title: "Реєстрацію скасовано/Registration has been canceled",
      text: "Реєстрацію успішно скасовано/Registration successfully canceled",
      variant: "warning"
    },
    error: {
      title: "Невідомо помилка/Unknown error",
      text: "Будь ласка, повідомте нам про це/Please let us know",
      variant: "warning"
    },
    student_bannded_admin: {
      title: "Студент успішно заблокований",
      text: "",
      variant: "warning"
    },
    student_registration_on_site_exist: {
      title:
        "Студент з даним email вже зареєстрований на сайті/A student with this email is already registered on the site",
      text: "",
      variant: "danger"
    },
    registered_email_success: {
      title: "Успішно зареєстровано!/Successfully registered!",
      text:
        "Вас буде автоматично переадресовано на головну сторінку/You will be automatically redirected to the homepage",
      variant: "success"
    }
  };

  return (
    <div className="ModalAlert2">
      <p />
      {errors.map(error => {
        console.log(error);
        return (
          <Alert
            dismissible
            onClick={() => {
              errorClose(error);
            }}
            variant={errorArray[error].variant}
          >
            <Alert.Heading>{errorArray[error].title}</Alert.Heading>
            <p>{errorArray[error].text}</p>
          </Alert>
        );
      })}
    </div>
  );
};

export default ModalAlert;
