import React, { Component } from "react";
import "./App.css";
import axios from "axios";
import { Link } from "react-router-dom";
import Cookies from "universal-cookie";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Loading from "./GlobalComponents/Loading/Loading";

class Departmants extends Component {
  constructor(props) {
    super(props);
    const cookies = new Cookies();
    let language = "";
    if ("undefined" === typeof cookies.get("language")) {
      language = "ua";
    } else {
      language = cookies.get("language");
    }
    this.state = {
      loading: true,
      language: language
    };
  }

  componentDidMount() {
    console.log(this.state.department_id);
    axios("https://listsever.e-decanat-ifnmu.site/api.php", {
      method: "post",
      data: {
        task: "GetDepartmentGroup",
        department_id: this.props.match.params.id
      },
      withCredentials: true
    }).then(response =>
      this.setState({ data: response.data.data, loading: false })
    );
  }

  render() {
    console.log(this.state);

    if (this.state.loading) {
      return <Loading />;
    }
    return (
      <Container>
        <Row>
          <Col>
            <div>
              <p />
              <ul>
                {this.state.data.map(group => {
                  return (
                    <li>
                      <Link to={"../GroupList/" + group.group_id}>
                        {group.name_en}
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default Departmants;
