import React, { Component } from "react";
import "./App.css";
import axios from "axios";
import { Link } from "react-router-dom";
import Loading from "./GlobalComponents/Loading/Loading";
import { Row, Col, Container } from "react-bootstrap";

class Departmants extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true
    };
  }

  componentDidMount() {
    axios("https://listsever.e-decanat-ifnmu.site/api.php", {
      method: "post",
      data: { task: "GetDepartments" },
      withCredentials: true
    }).then(response =>
      this.setState({ data: response.data.data, loading: false })
    );
  }

  render() {
    if (this.state.loading) {
      return <Loading />;
    }
    console.log(this.state);
    return (
      <Container>
        <Row>
          <Col>
            <div>
              <p></p>
              <ul>
                {this.state.data.map(department => {
                  return (
                    <li>
                      <Link to={"Department/" + department.department_id}>
                        {department.name_ua}
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default Departmants;
