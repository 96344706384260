import React from "react";
import Button from "react-bootstrap/Button";
import TimeOrIndex from "./ListTableRowComponents/TimeOrIndex";
import AdminCell from "./ListTableRowComponents/AdminCell";
import Atandence from "./ListTableRowComponents/Atandance/Atndence";

const ListTableRow = ({
  buttonPress,
  index,
  row,
  table,
  changeStatusRequest
}) => {
  let trStyle = {};

  let indexForFunction = index;
  let fn = "";
  let ln = "Місце вільне";
  let admin_cell = "";
  let course = (
    <Button
      variant="outline-secondary"
      size="sm"
      onClick={() =>
        buttonPress({
          task: "CreateRecord",
          taskData: {
            number_in_list: indexForFunction,
            list_id: table.list_id,
            listIndex: table.ListIndex
          }
        })
      }
    >
      Зареєструватись
    </Button>
  );
  if (table.showFreeRecords === true && row !== "empty") {
    if (row.semail === 0) {
      return "";
    }
  }

  if (row === "empty") {
    trStyle = { backgroundColor: "#F3FFEF" };
  }

  if (row === "deleted") {
    trStyle = { backgroundColor: "#E29693" };
    ln = "Видалено";
    course = (
      <Button
        variant="outline-secondary"
        size="sm"
        onClick={() => alert("функція в розробці")}
      >
        Скасувати видаленя
      </Button>
    );
  }
  if (row === "banned") {
    trStyle = { backgroundColor: "#E29693" };
    ln = "Заблоковано";
    fn = "на 4 дні";
    course = (
      <Button
        variant="outline-secondary"
        size="sm"
        onClick={() => alert("функція в розробці")}
      >
        Скасувати блокування
      </Button>
    );
  }
  if ((row !== "empty") & (row !== "deleted") & (row !== "banned")) {
    fn = row.sfirstname;
    ln = row.slastname;
    course = row.scourse + "/" + row.sgroup;
  }
  if (row.semail === 1) {
    ln = ln + "(Ваш запис)";
    course = (
      <Button
        variant="outline-secondary"
        size="sm"
        onClick={() =>
          buttonPress({
            task: "CancleRecord",
            modalText: {
              title: "Скасування реєстрації",
              descript: "Ви дійсно бажаєте скасувати реєстрацію?",
              ok: "Так",
              cancle: "Закрити"
            },
            taskData: {
              number_in_list: indexForFunction,
              list_id: table.list_id,
              listIndex: table.ListIndex
            }
          })
        }
      >
        Скасувати запис
      </Button>
    );
    trStyle = { backgroundColor: "#D8EDF7" };
  }

  if (
    (table.admin === true) &
    (row !== "empty") &
    (row !== "deleted") &
    (row !== "banned")
  ) {
    admin_cell = (
      <AdminCell
        index={indexForFunction}
        table={table}
        buttonPress={buttonPress}
      />
    );
  }
  if ((table.admin === true) & (row === "empty")) {
    admin_cell = <td />;
  }

  return (
    <tr style={trStyle} key={index}>
      <Atandence
        changeStatusRequest={changeStatusRequest}
        status={row.status}
        table={table}
        index={index}
      />
      <TimeOrIndex table={table} index={index} />
      <td> {ln}</td>
      <td> {fn}</td>
      <td> {course}</td>
      {admin_cell}
    </tr>
  );
};

export default ListTableRow;
